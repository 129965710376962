// Load Bootstrap JS
import bootstrap from 'bootstrap'
// Load Styles
import '../scss/main.scss';
// Load AOS
import aos from "aos";
aos.init();
 
// App code
var hamburgerOpen = false;
const Hamburger = document.querySelector('#hamburger');
const OffcanvasMenu = document.querySelector('#offcanvasMenu');
const Navbar = document.querySelector('#navbar');

OffcanvasMenu.addEventListener('hide.bs.offcanvas', function () {
    Hamburger.classList.remove('is-active');
    hamburgerOpen = false;
});
OffcanvasMenu.addEventListener('show.bs.offcanvas', function () {
    Hamburger.classList.add('is-active');
    hamburgerOpen = true;
});

/*
window.addEventListener('scroll', function() {
    var _y = window.pageYOffset;
    if(Navbar){
        if(_y > 100) { 
            Navbar.classList.add('bg-light');
        } else {
            Navbar.classList.remove('bg-light');
        }
    }
}, {passive: true});
*/
// console.log(`Hello ${process.env.VAR1}`);
